import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Row, Space, Spin, Typography } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../api";

const { RangePicker } = DatePicker;

const rangePresets = [
  {
    label: "Hôm nay",
    value: [dayjs(), dayjs()],
  },
  {
    label: "Hôm qua",
    value: [dayjs().add(-1, "d"), dayjs().add(-1, "d")],
  },
  {
    label: "Tuần này",
    value: [dayjs().startOf("week"), dayjs()],
  },
  {
    label: "Tuần trước",
    value: [
      dayjs().subtract(1, "week").startOf("week"),
      dayjs().subtract(1, "week").endOf("week"),
    ],
  },
  {
    label: "Tháng này",
    value: [dayjs().startOf("month"), dayjs()],
  },
  {
    label: "Tháng trước",
    value: [
      dayjs().subtract(1, "month").startOf("month"),
      dayjs().subtract(1, "month").endOf("month"),
    ],
  },
  {
    label: "Tất cả",
    value: [dayjs("01/07/2023", "DD/MM/YYYY"), dayjs()],
  },
];

export default function MapRequestStats() {
  const [stats, setStats] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [searchValue, setSearchValue] = useState({
    range: [dayjs(), dayjs()],
    from: dayjs().format("YYYY-MM-DD"),
    to: dayjs().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    onSearch();
  }, []);

  const onChangeSearchRange = (dates) => {
    setSearchValue((prev) => ({
      ...prev,
      range: dates,
      from: dates[0].format("YYYY-MM-DD"),
      to: dates[1].format("YYYY-MM-DD"),
    }));
  };

  const onSearch = async () => {
    setIsProcessing(true);
    try {
      const { from, to } = searchValue;
      const data = await api.getMapRequestStats({ from, to });
      if (data.error) return toast.error(data.error.msg);
      setStats(data);
    } catch (error) {
      console.log(error);
      toast.error("Có lỗi xảy ra");
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <>
      <Row justify="space-between" align="middle">
        <Typography.Title level={2}>Thống kê Map Request</Typography.Title>
        <Space size="middle">
          <RangePicker
            presets={rangePresets}
            value={searchValue.range}
            onChange={onChangeSearchRange}
          />
          <Button
            onClick={onSearch}
            icon={<SearchOutlined />}
            type="primary"
            loading={isProcessing}
          >
            Xem
          </Button>
        </Space>
      </Row>
      {isProcessing ? (
        <>
          <Spin />
        </>
      ) : stats ? (
        <Row>
          {stats.map((item) => (
            <Col span={6} key={item.region}>
              <h2>
                📍 {item.region} : {item.total}
              </h2>
              {item.types?.map((type) => (
                <li
                  key={type.type}
                  style={{ fontSize: 16, marginBottom: 20, paddingLeft: 20 }}
                >
                  {type.type.charAt(0).toUpperCase() + type.type.slice(1)}:{" "}
                  {type.count}
                </li>
              ))}
            </Col>
          ))}
        </Row>
      ) : (
        <h2>Không có dữ liệu</h2>
      )}
    </>
  );
}
