import {
  Avatar,
  Divider,
  Input,
  Modal,
  Row,
  Space,
  Switch,
  Table,
  Tag,
  Typography,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPhotoThumbnailUrl, priceToCurrency } from "utils/common";
import Actions from "./components/Actions";
import BlockUserModal from "./components/BlockUserModal";
import UserDetailModal from "./components/DetailModal";
import * as userActions from "./slice";
import WalletHistoryModal from "./components/DetailModal/WalletHistoryModal";

export default function User() {
  const dispatch = useDispatch();
  const { users, pagination, isLoading, isProcessing, isCompleted } =
    useSelector((state) => state.user);

  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [openWalletHistoryModal, setOpenWalletHistoryModal] = useState(false);
  const [openBlockUserModal, setOpenBlockUserModal] = useState(false);
  const [selectingUser, setSelectingUser] = useState(null);
  const [searchName, setSearchName] = useState("");
  const [searchHasBalance, setSearchHasBalance] = useState(false);

  useEffect(() => {
    dispatch(userActions.fetchUsers(pagination));
  }, []);

  useEffect(() => {
    if (isCompleted) {
      setOpenDetailModal(false);
      setOpenBlockUserModal(false);
      setOpenWalletHistoryModal(false);
      setSelectingUser(null);
    }
  }, [isCompleted]);

  const onOpenDetailModal = (user) => {
    setSelectingUser(user);
    setOpenDetailModal(true);
  };

  const onOpenWalletHistoryModal = (user) => {
    setSelectingUser(user);
    setOpenWalletHistoryModal(true);
  };

  const onCancel = () => {
    setOpenDetailModal(false);
    setSelectingUser(null);
    dispatch(userActions.setIsProcessing(false));
  };

  const onChangeNameSearch = (e) => {
    setSearchName(e.target.value);
  };

  const onChangeHasBalanceSearch = (checked) => {
    setSearchHasBalance(checked);
    dispatch(
      userActions.fetchUsers({
        ...pagination,
        name: searchName,
        hasBalance: checked,
      })
    );
  };

  const onSearch = () => {
    dispatch(
      userActions.fetchUsers({
        ...pagination,
        name: searchName,
        hasBalance: searchHasBalance,
        current: 1,
      })
    );
  };

  const onTableChange = async (pagination) => {
    dispatch(
      userActions.fetchUsers({
        ...pagination,
        name: searchName,
        hasBalance: searchHasBalance,
      })
    );
  };

  const onBlock = (user) => {
    setSelectingUser(user);
    setOpenBlockUserModal(true);
  };

  const onConfirmBlock = (user, hiddenReason) => {
    dispatch(userActions.blockUser(user, hiddenReason));
  };

  const onCancelBlock = () => {
    setOpenBlockUserModal(false);
    setSelectingUser(null);
  };

  const onCloseWalletHistoryModal = () => {
    setOpenWalletHistoryModal(false);
    setSelectingUser(null);
  };

  const onActivate = (user) => {
    Modal.confirm({
      title: `Xác nhận ${
        user.active ? "hủy kích hoạt" : "kích hoạt"
      } tài khoản`,
      content: `Bạn có chắc chắn muốn ${
        user.active ? "hủy kích hoạt" : "kích hoạt"
      } tài khoản này?`,
      onOk: () => {
        dispatch(userActions.activateUser(user));
      },
      centered: true,
    });
  };

  const columns = [
    {
      title: "STT",
      align: "center",
      width: "5%",
      render: (text, record, index) => (
        <Typography.Paragraph
          copyable={{
            text: record._id,
            tooltips: ["Sao chép ID", "Đã sao chép!"],
          }}
        >
          {(pagination.current - 1) * pagination.pageSize + index + 1}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Họ tên",
      width: "10%",
      render: (record) => (
        <>
          <Space size="small">
            {record.avatar ? (
              <Avatar
                alt="Avatar of user"
                src={getPhotoThumbnailUrl(record.avatar.path || null)}
                width={80}
                height={80}
              />
            ) : (
              <Avatar
                className="select-none"
                alt="Avatar of user"
                size={30}
                style={{ backgroundColor: "#FFD22F" }}
              >
                {record.name[0].toUpperCase()}
              </Avatar>
            )}
            <span>{record.name}</span>
          </Space>
        </>
      ),
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone",
      width: "8%",
    },
    {
      title: "Số dư ví",
      dataIndex: "balance",
      width: "6%",
      render: (data) => priceToCurrency(data),
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "10%",
    },
    {
      title: "Trạng thái",
      dataIndex: "active",
      align: "center",
      width: "8%",
      render: (active) =>
        active ? (
          <Tag color="#4caf50">Hoạt động</Tag>
        ) : (
          <Tag color="#f44336">Không hoạt động</Tag>
        ),
    },
    // {
    //   title: "Trạng thái chặn",
    //   dataIndex: "blocked",
    //   align: "center",
    //   width: "10%",
    //   render: (blocked) =>
    //     blocked ? (
    //       <Tag color="#f44336">Bị chặn</Tag>
    //     ) : (
    //       <Tag color="#4caf50">Bình thường</Tag>
    //     ),
    // },
    {
      title: "Ngày tham gia",
      dataIndex: "createdAt",
      width: "10%",
      render: (createdAt) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Thao tác",
      align: "center",
      width: "10%",
      render: (record) => (
        <Actions
          record={record}
          onView={onOpenDetailModal}
          onViewTransaction={onOpenWalletHistoryModal}
          onBlock={onBlock}
          onActivate={onActivate}
        />
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" align="middle">
        <Typography.Title level={2}>
          [{pagination?.total || "0"}] Người dùng
        </Typography.Title>
        <Space size="middle">
          <Switch
            checkedChildren="Có số dư"
            unCheckedChildren="Có số dư"
            onChange={onChangeHasBalanceSearch}
          />
          <Input.Search
            placeholder="Tìm theo tên hoặc SĐT"
            onChange={onChangeNameSearch}
            onSearch={onSearch}
            enterButton
            allowClear
          />
        </Space>
      </Row>
      <Divider />
      <Table
        bordered
        loading={isLoading}
        columns={columns}
        dataSource={users}
        rowKey={(record) => record._id}
        pagination={pagination}
        onChange={onTableChange}
      />
      {openDetailModal && (
        <UserDetailModal
          open={openDetailModal}
          isProcessing={isProcessing}
          onCancel={onCancel}
          user={selectingUser}
        />
      )}
      {openBlockUserModal && (
        <BlockUserModal
          open={openBlockUserModal}
          isProcessing={isProcessing}
          onSubmit={onConfirmBlock}
          onCancel={onCancelBlock}
          user={selectingUser}
        />
      )}
      {openWalletHistoryModal && (
        <WalletHistoryModal
          open={openWalletHistoryModal}
          user={selectingUser}
          onClose={onCloseWalletHistoryModal}
        />
      )}
    </>
  );
}
