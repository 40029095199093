import { Button, Col, Divider, Empty, Modal, Row, Space, Spin } from "antd";
import userApi from "features/user/api";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function WalletHistoryModal({ open, onClose, user }) {
  const [transactions, setTransactions] = useState(null);

  useEffect(() => {
    const getTransactions = async () => {
      const data = await userApi.getWalletTransactions(user._id);
      if (!data || data.error) {
        toast.error(data.error);
        setTransactions([]);
      } else setTransactions(data.data);
    };
    if (user) getTransactions();
  }, [user]);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      title="Lịch sử giao dịch"
      footer={[
        <Button type="primary" key="back" onClick={onClose}>
          OK
        </Button>,
      ]}
    >
      {transactions === null ? (
        <Spin />
      ) : transactions?.length ? (
        <div style={{ maxHeight: 400, overflow: "auto", paddingRight: 20 }}>
          {transactions.map((transaction) => (
            <div
              key={transaction._id}
              style={{ display: "block", marginBottom: "0" }}
            >
              <Row justify="space-between">
                <Col span={18}>
                  <h4>{transaction.name}</h4>
                </Col>
                <Col span={6} style={{ textAlign: "end" }}>
                  <h4>{transaction.amount}</h4>
                </Col>
              </Row>
              <Row justify="space-between" style={{ marginTop: -10 }}>
                <Col span={18}>
                  <span>{transaction.createdAt}</span>
                </Col>
                <Col span={6} style={{ textAlign: "end" }}>
                  {transaction.status === "success" ? (
                    <span style={{ color: "green" }}>Thành công</span>
                  ) : transaction.status === "fail" ? (
                    <span style={{ color: "red" }}>Thất bại</span>
                  ) : (
                    <span style={{ color: "orange" }}>Đang xử lý</span>
                  )}
                </Col>
              </Row>
            </div>
          ))}
        </div>
      ) : (
        <Empty description={`Không có dữ liệu!`} />
      )}
    </Modal>
  );
}
