import { Button, Divider } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchConfig, updateConfig } from "../slice";
import DebounceSelect from "components/common/DebounceSelect";
import commonApi from "utils/api";

export default function BlackRestaurants() {
  const dispatch = useDispatch();
  const { config, isLoading } = useSelector((state) => state.config);
  const [blackRestaurants, setBlackRestaurants] = useState("");

  useEffect(() => {
    dispatch(fetchConfig());
  }, [dispatch]);

  useEffect(() => {
    const blackRestaurants =
      config.blackRestaurants?.map((e) => ({
        key: e._id,
        label: e.name,
        value: e._id,
      })) || [];
    setBlackRestaurants(blackRestaurants);
  }, [config]);

  const onChangeRestaurants = (e) => {
    setBlackRestaurants(e);
  };

  const onSubmit = () => {
    const res = blackRestaurants.map((e) => e.value);
    dispatch(updateConfig({ blackRestaurants: res }));
  };

  return (
    <>
      <Divider orientation="left">
        Danh sách quán bị hạn chế gọi điện cho khách
      </Divider>
      <DebounceSelect
        mode="multiple"
        initOptions={
          config?.blackRestaurants?.map((e) => ({
            key: e._id,
            label: e.name,
            value: e._id,
          })) || []
        }
        fetchOptions={commonApi.searchRestaurant}
        convertOptions={(data) =>
          data.map((res) => ({
            key: res._id,
            label: res.name,
            value: res._id,
          }))
        }
        placeholder="Chọn danh sách quán bị hạn chế"
        value={blackRestaurants}
        autosize={{
          minRows: 4,
          maxRows: 10,
        }}
        onChange={onChangeRestaurants}
        style={{ width: "680px" }}
      />
      <br />
      <br />
      <Button
        type="primary"
        htmlType="submit"
        loading={isLoading}
        onClick={onSubmit}
      >
        Cập nhật
      </Button>
    </>
  );
}
