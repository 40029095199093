import axiosClient from "utils/api/axios-client";

const otpApi = {
  getOtpStats: (params) => {
    const url = "/api/v1/super-stats/otp/";
    return axiosClient.get(url, { params });
  },
  getMapRequestStats: (params) => {
    const url = "/api/v1/super-stats/map-request/";
    return axiosClient.get(url, { params });
  },
};

export default otpApi;
