import { Layout, Menu } from "antd";
import { default as Icon, default as Logo } from "assets/img/icon.png";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { routers } from "router";
const { Sider } = Layout;

import {
  AimOutlined,
  AppstoreOutlined,
  AreaChartOutlined,
  BarChartOutlined,
  BlockOutlined,
  CarOutlined,
  CrownOutlined,
  DashboardOutlined,
  DollarOutlined,
  EnvironmentOutlined,
  FileImageOutlined,
  FireOutlined,
  FormOutlined,
  HistoryOutlined,
  InboxOutlined,
  MessageOutlined,
  MobileOutlined,
  NotificationOutlined,
  PercentageOutlined,
  PhoneOutlined,
  PictureOutlined,
  RiseOutlined,
  RocketOutlined,
  SettingOutlined,
  ShakeOutlined,
  ShopOutlined,
  SnippetsOutlined,
  StarOutlined,
  TagOutlined,
  ThunderboltOutlined,
  ToolOutlined,
  TransactionOutlined,
  UnorderedListOutlined,
  UserOutlined,
  WalletOutlined,
  WarningOutlined,
  ZoomInOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";

export default function DashboardSider() {
  const navigate = useNavigate();
  const location = useLocation();
  // auto collapse sidebar when screen size is less than 768px
  const [collapsed, setCollapsed] = useState(window.innerWidth < 768);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);
  const { userInfo } = useSelector((state) => state.auth);

  const menuItems = {
    super_admin: [
      {
        key: routers.region,
        label: "Phân cụm",
        icon: <EnvironmentOutlined />,
      },
      {
        key: routers.superAccount,
        label: "Quản trị viên",
        icon: <CrownOutlined />,
      },
      {
        key: routers.superWalletRequest,
        label: "Nạp - Rút ví",
        icon: <WalletOutlined />,
      },
      {
        label: "Thiết lập chung",
        key: routers.superSetup.index,
        icon: <SettingOutlined />,
        children: [
          {
            label: "Hệ thống",
            key: [routers.superSetup.index, routers.superSetup.system].join(
              "/"
            ),
            icon: <ToolOutlined />,
          },
          {
            label: "Map Service",
            key: [routers.superSetup.index, routers.superSetup.mapService].join(
              "/"
            ),
            icon: <EnvironmentOutlined />,
          },
          {
            label: "Voucher đặt 1 đơn",
            key: [routers.superSetup.index, routers.superSetup.event].join("/"),
            icon: <TagOutlined />,
          },
        ],
      },
      {
        label: "Thống kê",
        key: routers.superStats.index,
        icon: <AreaChartOutlined />,
        children: [
          {
            label: "OTP",
            key: [routers.superStats.index, routers.superStats.otp].join("/"),
            icon: <ShakeOutlined />,
          },
          {
            label: "Map Request",
            key: [routers.superStats.index, routers.superStats.mapRequest].join(
              "/"
            ),
            icon: <EnvironmentOutlined />,
          },
        ],
      },
      {
        type: "divider",
        style: {
          borderColor: "inherit",
          margin: "12px 24px",
        },
      },
      {
        label: "Dashboard",
        key: routers.dashboard,
        icon: <DashboardOutlined />,
      },
      {
        label: "Danh mục đồ ăn",
        key: routers.category,
        icon: <InboxOutlined />,
      },
      {
        label: "Nhà hàng",
        key: routers.restaurant,
        icon: <ShopOutlined />,
      },
      {
        label: "Tài xế",
        key: routers.shipper.index,
        icon: <CarOutlined />,
        children: [
          {
            label: "Danh sách",
            key: [routers.shipper.index, routers.shipper.list].join("/"),
            icon: <UnorderedListOutlined />,
          },
          {
            label: "Vị trí",
            key: [routers.shipper.index, routers.shipper.map].join("/"),
            icon: <AimOutlined />,
          },
        ],
      },
      {
        label: "Đơn hàng",
        key: routers.order.index,
        icon: <FormOutlined />,
        children: [
          {
            label: "Trực tiếp",
            key: [routers.order.index, routers.order.newLive].join("/"),
            icon: <ThunderboltOutlined />,
          },
          {
            label: "Đang xử lý",
            key: [routers.order.index, routers.order.new].join("/"),
            icon: <SnippetsOutlined />,
          },
          {
            label: "Lịch sử",
            key: [routers.order.index, routers.order.history].join("/"),
            icon: <HistoryOutlined />,
          },
        ],
      },
      {
        label: "Người dùng",
        key: routers.user,
        icon: <UserOutlined />,
      },
      {
        label: "Banner",
        key: routers.banner.index,
        icon: <PictureOutlined />,
        children: [
          {
            label: "App user",
            key: [routers.banner.index, routers.banner.user].join("/"),
            icon: <MobileOutlined />,
          },
          {
            label: "App quán",
            key: [routers.banner.index, routers.banner.merchant].join("/"),
            icon: <ShopOutlined />,
          },
        ],
      },
      {
        label: "Chiến dịch",
        key: routers.campaign,
        icon: <RiseOutlined />,
      },
      {
        label: "Nhãn quán",
        key: routers.restaurantLabel,
        icon: <FileImageOutlined />,
      },
      {
        label: "Bộ sưu tập",
        key: routers.collection,
        icon: <AppstoreOutlined />,
      },

      {
        label: "Quảng cáo",
        key: routers.ad.index,
        icon: <FireOutlined />,
        children: [
          {
            label: "Danh sách",
            key: [routers.ad.index, routers.ad.list].join("/"),
            icon: <RocketOutlined />,
          },
          {
            label: "Loại hình",
            key: [routers.ad.index, routers.ad.type].join("/"),
            icon: <BlockOutlined />,
          },
        ],
      },
      {
        label: "Voucher",
        key: routers.voucher,
        icon: <TagOutlined />,
      },
      {
        label: "Thông báo",
        key: routers.notification,
        icon: <NotificationOutlined />,
      },
      {
        label: "Hòm thư",
        key: routers.message,
        icon: <MessageOutlined />,
      },
      {
        label: "Đánh giá",
        key: routers.review.index,
        icon: <StarOutlined />,
        children: [
          {
            label: "Nhà hàng",
            key: [routers.review.index, routers.review.restaurant].join("/"),
            icon: <ShopOutlined />,
          },
          {
            label: "Tài xế",
            key: [routers.review.index, routers.review.shipper].join("/"),
            icon: <CarOutlined />,
          },
        ],
      },
      {
        label: "Liên hệ",
        key: routers.contact,
        icon: <PhoneOutlined />,
      },
      {
        label: "Lệnh nạp - rút ví",
        key: routers.walletRequest,
        icon: <TransactionOutlined />,
      },
      {
        label: "Số dư ví",
        key: routers.wallet,
        icon: <WalletOutlined />,
      },
      {
        label: "Tài khoản",
        key: routers.account,
        icon: <UserOutlined />,
      },
      {
        label: "Thiết lập",
        key: routers.setup.index,
        icon: <SettingOutlined />,
        children: [
          {
            label: "Phí - Chiết khấu",
            key: [routers.setup.index, routers.setup.feeCharge].join("/"),
            icon: <PercentageOutlined />,
          },
          // {
          //   label: "Tặng voucher",
          //   key: [routers.setup.index, routers.setup.freeVoucher].join("/"),
          //   icon: <GiftOutlined />,
          // },
          {
            label: "Thưởng tài xế",
            key: [routers.setup.index, routers.setup.rewards].join("/"),
            icon: <DollarOutlined />,
          },
          {
            label: "Từ khóa nổi bật",
            key: [routers.setup.index, routers.setup.hotKeyword].join("/"),
            icon: <ZoomInOutlined />,
          },
          {
            label: "Quán bị hạn chế",
            key: [routers.setup.index, routers.setup.blackRestaurants].join(
              "/"
            ),
            icon: <WarningOutlined />,
          },
          {
            label: "Thiết lập khác",
            key: [routers.setup.index, routers.setup.other].join("/"),
            icon: <ToolOutlined />,
          },
        ],
      },
      {
        label: "Báo cáo",
        key: routers.report.index,
        icon: <BarChartOutlined />,
        children: [
          {
            label: "Doanh thu nhà hàng",
            key: [routers.report.index, routers.report.restaurantRevenue].join(
              "/"
            ),
            icon: <DollarOutlined />,
          },
        ],
      },
    ],
    admin: [],
    customer_service: [
      {
        label: "Tài xế",
        key: routers.shipper.index,
        icon: <CarOutlined />,
        children: [
          {
            label: "Danh sách",
            key: [routers.shipper.index, routers.shipper.list].join("/"),
            icon: <UnorderedListOutlined />,
          },
          {
            label: "Vị trí",
            key: [routers.shipper.index, routers.shipper.map].join("/"),
            icon: <AimOutlined />,
          },
        ],
      },
      {
        label: "Đơn hàng",
        key: routers.order.index,
        icon: <FormOutlined />,
        children: [
          {
            label: "Trực tiếp",
            key: [routers.order.index, routers.order.newLive].join("/"),
            icon: <ThunderboltOutlined />,
          },
          {
            label: "Đang xử lý",
            key: [routers.order.index, routers.order.new].join("/"),
            icon: <SnippetsOutlined />,
          },
          {
            label: "Lịch sử",
            key: [routers.order.index, routers.order.history].join("/"),
            icon: <HistoryOutlined />,
          },
        ],
      },
      {
        label: "Đánh giá",
        key: routers.review.index,
        icon: <StarOutlined />,
        children: [
          {
            label: "Nhà hàng",
            key: [routers.review.index, routers.review.restaurant].join("/"),
            icon: <ShopOutlined />,
          },
          {
            label: "Tài xế",
            key: [routers.review.index, routers.review.shipper].join("/"),
            icon: <CarOutlined />,
          },
        ],
      },
    ],
  };

  menuItems.admin = menuItems.super_admin.slice(5);

  useEffect(() => {
    setSelectedKeys(location.pathname.slice(1));
    setOpenKeys(location.pathname.split("/").slice(1));
  }, [location]);

  const onSelectMenuItem = (item) => {
    navigate(item.key);
  };

  const onOpenChangeSubMenu = (item) => {
    setOpenKeys(item);
  };

  return (
    <>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        className="overflow-auto h-screen !sticky inset-0"
        width={250}
      >
        <div className="p-4" style={{ textAlign: "center" }}>
          <img
            src={collapsed ? Icon : Logo}
            alt="logo"
            className="w-full"
            style={{ width: "100px" }}
          />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={selectedKeys}
          openKeys={openKeys}
          items={menuItems[userInfo.role]}
          onSelect={onSelectMenuItem}
          onOpenChange={onOpenChangeSubMenu}
        />
      </Sider>
    </>
  );
}
