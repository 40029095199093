import axiosClient from "utils/api/axios-client";

const walletRequestApi = {
  getAll: (params) => {
    const url = "/api/v1/wallet-requests";
    return axiosClient.get(url, { params });
  },
  get: (id) => {
    const url = `/api/v1/wallet-requests/${id}`;
    return axiosClient.get(url);
  },
  create: (walletRequest) => {
    const url = "/api/v1/wallet-requests";
    return axiosClient.post(url, walletRequest);
  },
  recharge: (walletRequest) => {
    const url = `/api/v1/wallet-requests/recharge`;
    return axiosClient.post(url, walletRequest);
  },
  updateStatus: (walletRequest) => {
    const url = `/api/v1/wallet-requests/${walletRequest._id}/status`;
    return axiosClient.put(url, walletRequest);
  },
};

export default walletRequestApi;
