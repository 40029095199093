import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "features/dashboard";
import Login from "features/auth/login";
import User from "features/user";
import { DashboardLayout } from "components";
import Restaurant from "features/restaurant";
import ShipperList from "features/shipper/list";
import ShipperMap from "features/shipper/map";
import NewOrderLive from "features/order/new-live";
import NewOrder from "features/order/new";
import HistoryOrder from "features/order/history";
import BannerUser from "features/banner/app-user";
import BannerMerchant from "features/banner/app-merchant";
import Campaign from "features/campaign";
import Collection from "features/collection";
import AdList from "features/ad/list";
import AdType from "features/ad/type";
import Voucher from "features/voucher";
import Notification from "features/notification";
import RestaurantReview from "features/review/restaurant";
import ShipperReview from "features/review/shipper";
import Contact from "features/contact";
import WalletRequest from "features/wallet-request";
import ShipperRewards from "features/setup/shipper-rewards";
import Account from "features/account";
import Category from "features/category";
import Private from "components/common/private";
import FeeCharge from "features/setup/fee-charger";
import Message from "features/message";
import HotKeywords from "features/setup/hot-keyword";
import OtherConfig from "features/setup/other-config";
import RestaurantRevenue from "features/report/restaurant-revenue";
import Region from "features/region";
import SuperAccount from "features/super-account";
import Event11 from "features/super-setup/event-1-1";
import MapService from "features/super-setup/map-service";
import RestaurantLabel from "features/restaurant-label";
import System from "features/super-setup/system";
import SuperWalletRequest from "features/super-wallet-request";
import Wallet from "features/wallet";
import StatsOtp from "features/super-stats/otp";
import StatsMapRequest from "features/super-stats/map-request";
import BlackRestaurants from "features/setup/black-restaurants";

export const routers = {
  dashboard: "",
  login: "dang-nhap",
  region: "phan-cum",
  superAccount: "quan-tri-vien",
  superWalletRequest: "nap-rut-vi",
  superSetup: {
    index: "cai-dat-chung",
    system: "system",
    event: "event-1-1",
    mapService: "map-service",
  },
  superStats: {
    index: "thong-ke",
    otp: "otp",
    mapRequest: "map-request",
  },
  restaurant: "nha-hang",
  shipper: {
    index: "tai-xe",
    list: "danh-sach",
    map: "vi-tri",
  },
  user: "nguoi-dung",
  account: "tai-khoan",
  category: "danh-muc",
  order: {
    index: "don-hang",
    newLive: "don-hang-moi-live",
    new: "don-hang-moi",
    history: "lich-su-don-hang",
  },
  banner: {
    index: "banner",
    user: "app-user",
    merchant: "app-merchant",
  },
  campaign: "chien-dich",
  restaurantLabel: "nhan-quan",
  ad: {
    index: "quang-cao",
    list: "danh-sach",
    type: "loai-quang-cao",
  },
  collection: "bo-suu-tap",
  voucher: "voucher",
  notification: "thong-bao",
  message: "tin-nhan",
  review: {
    index: "danh-gia",
    restaurant: "nha-hang",
    shipper: "tai-xe",
  },
  contact: "lien-he",
  walletRequest: "lenh-nap-rut-tien",
  wallet: "vi-cum",
  setup: {
    index: "cai-dat",
    feeCharge: "phi-chiet-khau",
    hotKeyword: "tu-khoa-noi-bat",
    blackRestaurants: "quan-bi-han-che",
    rewards: "thuong-tai-xe",
    other: "khac",
  },
  report: {
    index: "bao-cao",
    restaurantRevenue: "doanh-thu-quan",
  },
  // userDetail: (id) => (id ? `/user/${id}` : "/user"),
};

const Routers = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<DashboardLayout />}>
          <Route index element={<Private component={Dashboard} />} />
          <Route
            path={routers.region}
            element={<Private component={Region} />}
          />
          <Route
            path={routers.superAccount}
            element={<Private component={SuperAccount} />}
          />
          <Route
            path={routers.superWalletRequest}
            element={<Private component={SuperWalletRequest} />}
          />
          <Route path={routers.superSetup.index}>
            <Route
              path={routers.superSetup.event}
              element={<Private component={Event11} />}
            />
            <Route
              path={routers.superSetup.mapService}
              element={<Private component={MapService} />}
            />
            <Route
              path={routers.superSetup.system}
              element={<Private component={System} />}
            />
          </Route>
          <Route path={routers.superStats.index}>
            <Route
              path={routers.superStats.otp}
              element={<Private component={StatsOtp} />}
            />
            <Route
              path={routers.superStats.mapRequest}
              element={<Private component={StatsMapRequest} />}
            />
          </Route>
          <Route
            path={routers.account}
            element={<Private component={Account} />}
          />
          <Route
            path={routers.category}
            element={<Private component={Category} />}
          />
          <Route
            path={routers.restaurant}
            element={<Private component={Restaurant} />}
          />
          <Route path={routers.shipper.index}>
            <Route
              path={routers.shipper.list}
              element={<Private component={ShipperList} />}
            />
            <Route
              path={routers.shipper.map}
              element={<Private component={ShipperMap} />}
            />
          </Route>
          <Route path={routers.user} element={<Private component={User} />} />
          <Route path={routers.order.index}>
            <Route
              path={routers.order.newLive}
              element={<Private component={NewOrderLive} />}
            />
            <Route
              path={routers.order.new}
              element={<Private component={NewOrder} />}
            />
            <Route
              path={routers.order.history}
              element={<Private component={HistoryOrder} />}
            />
          </Route>
          <Route path={routers.banner.index}>
            <Route
              path={routers.banner.user}
              element={<Private component={BannerUser} />}
            />
            <Route
              path={routers.banner.merchant}
              element={<Private component={BannerMerchant} />}
            />
          </Route>
          <Route
            path={routers.campaign}
            element={<Private component={Campaign} />}
          />
          <Route
            path={routers.restaurantLabel}
            element={<Private component={RestaurantLabel} />}
          />
          <Route
            path={routers.collection}
            element={<Private component={Collection} />}
          />

          <Route path={routers.ad.index}>
            <Route
              path={routers.ad.list}
              element={<Private component={AdList} />}
            />
            <Route
              path={routers.ad.type}
              element={<Private component={AdType} />}
            />
          </Route>
          <Route
            path={routers.voucher}
            element={<Private component={Voucher} />}
          />
          <Route
            path={routers.notification}
            element={<Private component={Notification} />}
          />
          <Route
            path={routers.message}
            element={<Private component={Message} />}
          />
          <Route path={routers.review.index}>
            <Route
              path={routers.review.restaurant}
              element={<Private component={RestaurantReview} />}
            />
            <Route
              path={routers.review.shipper}
              element={<Private component={ShipperReview} />}
            />
          </Route>
          <Route
            path={routers.contact}
            element={<Private component={Contact} />}
          />
          <Route
            path={routers.walletRequest}
            element={<Private component={WalletRequest} />}
          />
          <Route
            path={routers.wallet}
            element={<Private component={Wallet} />}
          />
          <Route path={routers.setup.index}>
            <Route
              path={routers.setup.feeCharge}
              element={<Private component={FeeCharge} />}
            />
            <Route
              path={routers.setup.hotKeyword}
              element={<Private component={HotKeywords} />}
            />
            <Route
              path={routers.setup.blackRestaurants}
              element={<Private component={BlackRestaurants} />}
            />
            <Route
              path={routers.setup.rewards}
              element={<Private component={ShipperRewards} />}
            />
            <Route
              path={routers.setup.other}
              element={<Private component={OtherConfig} />}
            />
          </Route>
          <Route path={routers.report.index}>
            <Route
              path={routers.report.restaurantRevenue}
              element={<Private component={RestaurantRevenue} />}
            />
          </Route>
        </Route>
        <Route path={routers.login} element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
